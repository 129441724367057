import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import { mq } from "~/components/common/theme"
import HeroVideo from "~/components/page/HeroVideo"
import { InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import { Heading, Text } from "~/components/common/Typography"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 5, mySubpage = 3 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "6.4HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1: file(name: { eq: "6.4a-ADIDAS-network" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "6.4b-ADIDAS-bonds" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "6.4c-ADIDAS-community" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, graphic1, graphic2, graphic3 } = data

  const graphicsData = [
    {
      image: graphic1,
      title: "Entry into a social network that is rooted in achievement",
      Text: () => (
        <span>
          Per an article from the Children’s Medical Group, “Sports provide
          girls a core of buddies, integrating them, as researchers at Catholic
          University argue, into male-type ‘networks that are larger, less
          intimate, and more based on achievement’ than the small, intense
          friendship groups based on building and maintaining relationships to
          which girls are naturally drawn. This type of network may give female
          athletes an edge in other areas of achievement as well.”
          <Source>65</Source>
        </span>
      ),
    },
    {
      image: graphic2,
      title: "Greater bonds among peers",
      Text: () => (
        <span>
          Research shared by ESPN found that “girls who play sports are 10% more
          likely to say they ’trust other girls’ than girls who don't, while
          they're 7 percentage points more likely to ‘get along well with other
          girls.’”<Source>66</Source>
          <br/>
          In addition, “High school girls who play sports are significantly more
          likely to have supportive friends.”<Source>67</Source>
        </span>
      ),
    },
    {
      image: graphic3,
      title: "Higher body esteem",
      Text: () => (
        <span>
          Participation in sports can also have a long-term impact on the social
          lives of girls. Girls who play sports are more likely to be involved
          in other extracurricular and community activities.<Source>68</Source>
          <br/>
          Girls who play sports will encounter a greater number of adult role
          models from whom they can draw inspiration and support.
          <Source>69</Source>
          <br/>
          Girls who play sports have “more positive and expansive views on
          girls’ roles and opportunities related to careers and leadership.”
          <Source>70</Source>
        </span>
      ),
    },
  ]

  const graphicMq = mq({
    width: ["70%", "70%", "80%"],
  })

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge={`"Sports is nothing more than organizing a group for high performance. And that's what businesses do."`}
        source={"—Donna Lopiano, former CEO of the Women's Sports Foundation"}
        sourceProps={{
          uppercase: true,
          fontFamily: "AdineuePROBoldWeb",
          color: "black",
        }}
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed>
        <Flex
          maxWidth={"994px"}
          mx={"auto"}
          justifyContent={"center"}
          alignItems={"start"}
          css={css`
            flex-wrap: wrap;
          `}
        >
          {graphicsData.map((item, index) => (
            <GraphicWithText
              mq={graphicMq}
              image={item.image}
              width={["100%", "33%"]}
              key={index}
              px={6}
            >
              <Box fontSize={["16px", "16px"]} lineHeight={["18px", "27px"]}>
                <Heading
                  uppercase
                  as="h3"
                  fontFamily={"AdineuePROBoldWeb"}
                  fontSize={["16px", "16px"]}
                  lineHeight={["18px", "21px"]}
                >
                  {item.title}
                </Heading>
                <Text mt={0}>{item.Text && <item.Text></item.Text>}</Text>
              </Box>
            </GraphicWithText>
          ))}
        </Flex>
      </InfoboxDetailed>

      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 5, 3)
